import React from 'react';
import milaSquareNew from 'assets/images/mila-square-new.png';
import milaSquareNewWebp from 'assets/images/mila-square-new.png?as=webp';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import { EMOJI } from '../../../shared/enums';

const FadeIn = loadable(() => import('../common/FadeIn'));

const FeatureBlockTopGap = () => (
  <section className='feature-block feature-block--top-gap'>
    <div className='container'>
      <div className='feature-block__item feature-block--reverse feature-block--square-image'>
        <figure className='feature-block__image feature-block__image--animate3'>
          <LazyLoad>
            <FadeIn>
              <picture>
                <source srcSet={milaSquareNewWebp} type='image/webp' />
                <source srcSet={milaSquareNew} type='image/png' />
                <img
                  src={milaSquareNew}
                  alt='mila filter in white'
                />
              </picture>
            </FadeIn>
          </LazyLoad>
        </figure>
        <div className='feature-block__content'>
          <h2 className='feature-block__title'>
            And yes, she’s
            {' '}
            <span className='strikethrough'>probably</span>
            {' '}
            the cutest
            air purifier ever.
            {' '}
            {EMOJI.HUGGING_FACE}
          </h2>
          <div className='feature-block__description'>
            <p>Just look at that face. She looks great in every room.</p>
            <Link to='/why-mila' className='link-arrow'>
              <span className='screen-reader-text'>Visit Whymila page</span>
              <svg className='icon icon-line-arrow'>
                <use xlinkHref='#icon-line-arrow' />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FeatureBlockTopGap;
